import { BaseVariant, useSnackbar } from "notistack"
import { createContext, ReactNode, useContext } from "react"

interface IGlobalContext {
  openToast: (text: string, type?: BaseVariant) => void
}

const NewGlobalContext = createContext<IGlobalContext | undefined>(undefined)

export const useGlobalContext = (): IGlobalContext => {
  const context = useContext(NewGlobalContext)
  if (context === undefined) throw Error("GlobalContext is not right")
  return context
}

export const WrapperGlobalContext = ({ children }: { children: ReactNode }) => {
  const { enqueueSnackbar } = useSnackbar()

  const finalContext: IGlobalContext = {
    openToast: (text, type = "default") => {
      enqueueSnackbar(text, { variant: type, anchorOrigin: { vertical: "top", horizontal: "center" } })
    },
  }

  return <NewGlobalContext.Provider value={finalContext}>{children}</NewGlobalContext.Provider>
}
