import { ReactNode } from "react"

import mainImage from "../assets/products/VISTA48LAPLUS.png"
import Typography from "../components/Typography"

// const whyUS = [
//   {
//     // image: iconPiggy,
//     title: "Precios Accesibles",
//     description: "Proteja lo que mas le importa con los mejores equipos y al mejor precio del mercado",
//   },
//   {
//     // image: icon24h7,
//     title: "Monitoreo 24/7",
//     description: "Contamos con la mas alta tecnología del mercado para ofrecerle el mejor servicio",
//   },
//   {
//     // image: iconWrench,
//     title: "Expertos Calificados",
//     description: "Nuestros técnicos están altamente capacitados en las mejores marcas del mercado",
//   },
// ]

const ProductosWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <hr />
      <section className="py-12 sm:py-14 md:py-16 px-6 sm:px-9 md:px-12">
        <div className="max-w-6xl mx-auto">{children}</div>
      </section>
    </>
  )
}

const vista48 = [
  {
    title: "Sistema",
    content: [
      // h
      "Programación por teclado alfanumérico, software Downloading.",
      "Máximo 8 teclados, soporta teclados alfanuméricos, de iconos y sensibles al tacto; máximo 2 (se sugiere fuente de poder auxiliar).",
      "Tiempo de entrada definido por el instalador.",
      "2 zonas programables de entrada y salida con distintos tiempos.",
      "Funciones de automatización.",
      "Función de auto armado y desarmado.",
      "Soporta módulo de relevadores 4204, máximo 4.",
      "250 eventos en memoria. La memoria se puede leer con el software COMPASS Downloading.",
      "3 particiones con opción de configurar una como lobby.",
      "Confirmación de armado y desarmado.",
      "Auto Armado parcial configurable.",
      "Salida de sirena de 2 A.",
      "2 salidas de voltaje programables.",
      "Detección de corte de línea telefónica.",
      "Corriente auxiliar de 600 mA",
      "Entrada de alimentación de 16 Vca.",
    ],
  },
  {
    title: "Zonas",
    content: [
      // h
      "8 zonas programables, configurables con o sin resistencia de fin de línea.",
      "Las zonas se pueden configurar de respuesta rápida o lenta.",
      "Capacidad de duplicación de zonas.",
      "En la zona uno soporta hasta 16 detectores de humo de dos hilos.",
      "Soporta detectores de humo de 4 hilos.",
      "Soporta 40 zonas inalámbricas.",
      "24 tipos de zonas configurables.",
      "16 zonas adicionales para controles portátiles.",
      "Soporta módulo de expansión de zonas cableados 4219 y 4229.",
      "Supervisión del sistema inalámbrico al 100%.",
      "Alcance de hasta 60 m en la serie inalámbrica 5800.",
    ],
  },
  {
    title: "Usuarios",
    content: [
      // h
      "48 códigos de usuario.",
      "6 niveles de autoridad: instalador, maestro, estándar, invitado, sólo armar y coacción.",
      "Sistema de armado rápido.",
      "5 niveles de armado: total, parcial, noche, instantáneo y máximo.",
      "Aviso de puerta abierta (CHIME).",
    ],
  },
  {
    title: "Comunicación",
    content: [
      // h
      "Comunicador interconstruído para comunicación con la Central Receptora de Alarmas.",
      "Soporta los formatos de comunicación en 4+2 y CONTAC/ID.",
      "Soporta opción de reporte dual y dividido.",
      "Compatible con los sistemas de comunicación de Honeywell para AlarmNet.",
    ],
  },
  {
    title: "Incluye",
    content: [
      // h
      "1 Panel de alarma VISTA48, teclado con receptor inalambrico y gabinete VISTA48/6162RF ",
      "3 Contactos magneticos inalambricos 5816",
      "3 Contactos con iman SF2031",
      "2 Sensores de movimiento inalambricos 5800PIR",
      "1 Control inalambrico 5834-4",
      "1 Bateria PL4.512» 1 Transformador de 16Vca",
      "1 Transformador de 16.5 Vca 1361GT ",
    ],
  },
]

function Productos() {
  return (
    <div>
      <ProductosWrapper>
        <div className="flex-initial lg:flex flex-row md:flex-row-reverse">
          <div className="basis-full px-2 sm:px-4 md:px-6 text-center lg:text-left">
            <Typography component="h2" className="text-left bold">
              Kit inalámbrico / Panel VISTA48LA con teclado 6162RF receptor inalámbrico / 3 contactos 5816 / 2 PIR 5800PIR / 1 Llavero inalambrico / Bateria y Transformador
            </Typography>
          </div>
          <div className="basis-full sm:basis-1/2 md:basis-2/3 px-2 sm:px-4 md:px-6 pt-4 lg:pt-0">
            <img src={mainImage} alt="all-products-included" className="mx-auto w-3/4 sm:w-1/2 md:w-1/2 lg:w-full" />
          </div>
        </div>
      </ProductosWrapper>
      <ProductosWrapper>
        <Typography component="p">Panel de alarma para aplicaciones residenciales, negocios u oficinas.</Typography>
        {vista48.map((item) => {
          return (
            <div>
              <Typography component="p" className="font-bold">
                {item.title}
              </Typography>
              <ul>
                {item.content.map((stringText) => {
                  return (
                    <li>
                      <Typography component="p">{stringText}</Typography>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
        <Typography component="p" className="font-bold">
          * 1 año de garantía.
        </Typography>
      </ProductosWrapper>
    </div>
  )
}

export default Productos
