import { ReactNode } from "react"

interface ITypography {
  component: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p"
  className?: string
  children: ReactNode
}

function Typography({ component, className, children }: ITypography) {
  return (
    <>
      {component === "h1" && <h1 className={`text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold ${className}`}>{children}</h1>}
      {component === "h2" && <h2 className={`text-xl sm:text-2xl md:text-3xl lg:text-4xl lg:leading-[3rem] font-bold ${className}`}>{children}</h2>}
      {component === "h3" && <h3 className={`text-lg sm:text-xl md:text-2xl font-bold ${className}`}>{children}</h3>}
      {component === "h4" && <h4 className={`text-base sm:text-lg md:text-xl font-bold ${className}`}>{children}</h4>}
      {component === "h5" && <h5 className={`text-sm sm:text-base md:text-lg font-bold ${className}`}>{children}</h5>}
      {component === "h6" && <h6 className={`text-sm sm:text-base md:text-lg font-normal ${className}`}>{children}</h6>}
      {component === "p" && <p className={`text-xs sm:text-sm leading-5 sm:leading-6 ${className}`}>{children}</p>}
    </>
  )
}

export default Typography
