import { Link } from "react-router-dom"

import { emailContact, emailSales, whatsappNumber } from "../utils"
import Typography from "./Typography"

import footerClock from "../assets/footer-clock.png"
import footerMail from "../assets/footer-mail.png"
import footerPhone from "../assets/footer-phone.png"

const footerInfo = [
  {
    icon: footerMail,
    title: "Correo Electrónico",
    text1: emailSales,
    text2: emailContact,
  },
  {
    icon: footerClock,
    title: "Horario de Atención",
    text1: "9:00 AM - 6:00 PM",
    text2: "Lunes - Sabado",
  },
  {
    icon: footerPhone,
    title: "Teléfono Móvil",
    text1: whatsappNumber,
    text2: "WhatsApp",
  },
]

function Footer() {
  const currentYear = new Date(Date.now()).getFullYear()

  return (
    <footer style={{ backgroundImage: "linear-gradient(180deg,rgb(35,37,38) 0%,rgb(0,0,0) 100%)" }}>
      <div className="pt-10 sm:pt-14 md:pt-16 pb-14 sm:pb-16 md:pb-20 px-6 sm:px-9 md:px-12">
        <div className="max-w-6xl mx-auto text-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 md:gap-6">
            {footerInfo.map((column, index) => (
              <div key={index} className="inline-block">
                <div className="h-full text-center items-center">
                  <img className="w-12 mx-auto py-6" src={column.icon} alt={`Footer ${index}`} />
                  <Typography component="h6" className="text-white">
                    {column.title}
                  </Typography>
                  <div className="pt-4 text-[#ffffff99]">
                    <Typography component="p">{column.text1}</Typography>
                    <Typography component="p">{column.text2}</Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="border-t border-solid border-[#333333]">
        <div className="py-4 sm:py-6 md:py-8 px-6 sm:px-9 md:px-12">
          <div className="max-w-6xl mx-auto flex flex-wrap justify-between flex-col sm:flex-row text-[#adadad]">
            <Typography component="p" className="inline-flex mx-auto sm:mx-0 py-2 sm:py-0 hover:underline cursor-pointer">
              <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link>
            </Typography>
            <Typography component="p" className="inline-flex mx-auto sm:mx-0 py-2 sm:py-0">
              Copyright © {currentYear} Centro Aquila
            </Typography>
            <Typography component="p" className="inline-flex mx-auto sm:mx-0 py-2 sm:py-0 hover:underline cursor-pointer">
              <Link to="/terminos-y-condiciones">Terminos y Condiciones</Link>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
