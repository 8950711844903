import picadilloImage from "../assets/picadillo.jpg"
import Typography from "../components/Typography"

function Picadillo() {
  return (
    <div>
      <hr />
      <section className="pt-12 pb-0 md:pb-16 px-6 sm:px-9 md:px-12">
        <div className="max-w-6xl mx-auto text-center">
          <Typography component="h2" className="pb-5">
            Conozca a Picadillo
          </Typography>
          <img className="w-full" src={picadilloImage} alt="rabbit-watching-you" />
        </div>
      </section>
    </div>
  )
}

export default Picadillo
