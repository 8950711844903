import { useState } from "react"
import Helmet from "react-helmet"
import { FaBars, FaEnvelopeOpenText, FaFacebook, FaHome, FaPeopleCarry, FaTimes, FaWhatsapp, FaWrench } from "react-icons/fa"
import { FaLocationDot } from "react-icons/fa6"
import { Link, useLocation } from "react-router-dom"

import { facebookURL, ubicameURL, whatsappURL } from "../utils"

import aquilaLogoHD from "../assets/aquila-logo-hd.png"
import aquilaLogoSD from "../assets/aquila-logo-sd.png"
import aquilaLogoXL from "../assets/aquila-logo-xl.png"

const insidePages = [
  {
    text: "Inicio",
    icon: <FaHome />,
    href: "/",
  },
  {
    text: "Servicios",
    icon: <FaWrench />,
    href: "/servicios",
  },
  {
    text: "Nosotros",
    icon: <FaPeopleCarry />,
    href: "/nosotros",
  },
  {
    text: "Contacto",
    icon: <FaEnvelopeOpenText />,
    href: "/contacto",
  },
]

const socialMedia = [
  {
    text: "Facebook",
    icon: <FaFacebook />,
    href: facebookURL,
  },
  {
    text: "WhatsApp",
    icon: <FaWhatsapp />,
    href: whatsappURL,
  },
  {
    text: "Ubicación",
    icon: <FaLocationDot />,
    href: ubicameURL,
  },
]

const Sidebar = () => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen((current) => !current)
  return (
    <>
      <button onClick={toggleOpen} aria-controls="navbar-default" aria-expanded="false" className="inline-flex md:hidden items-center p-2 ml-3 border-0 bg-gray-200 hover:bg-gray-300">
        <FaBars className="w-6 h-6 text-sm text-white" />
      </button>
      <div className={`${open ? "flex" : "hidden"} flex-col h-full p-5 w-60 bg-gray-900 text-gray-100 fixed top-0 right-0 z-10`}>
        <div>
          <div className="flex items-center justify-between select-none">
            <h2>Centro Aquila</h2>
            <button className="p-2" onClick={toggleOpen}>
              <FaTimes className="w-4 h-4 text-sm text-white" />
            </button>
          </div>
          <hr className="my-3" />
          <ul className="space-y-1 text-sm">
            {insidePages.map((value, index) => {
              return (
                <li key={index} onClick={toggleOpen}>
                  <Link to={value.href} className="flex items-center py-2 space-x-3">
                    <span className="w-6 h-6 text-lg flex align-middle text-center items-center">{value.icon}</span>
                    <span>{value.text}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
          <hr className="my-3" />
          <ul className="space-y-1 text-sm">
            {socialMedia.map((value, index) => {
              return (
                <li key={index} onClick={toggleOpen}>
                  <a href={value.href} className="flex items-center py-2 space-x-3" target="_blank" rel="noopener noreferrer">
                    <span className="w-6 h-6 text-lg flex align-middle text-center items-center">{value.icon}</span>
                    <span>{value.text}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

function Navbar() {
  const { pathname } = useLocation()

  const browserTitle = (): string => {
    if (pathname === "/servicios") return "Servicios - Centro Aquila"
    if (pathname === "/nosotros") return "Nosotros - Centro Aquila"
    if (pathname === "/contacto") return "Contacto - Centro Aquila"
    else return "Centro Aquila - Seguridad en la que puede confiar"
  }

  return (
    <>
      <Helmet>
        <title>{browserTitle()}</title>
      </Helmet>
      <nav className="bg-white px-8 py-4">
        <div className="container flex flex-wrap justify-between items-center mx-auto max-w-7xl">
          <Link to="/">
            <img width="80" height="62" src={aquilaLogoSD} alt="Aquila Logo" srcSet={`${aquilaLogoSD} 80w, ${aquilaLogoHD} 300w, ${aquilaLogoXL} 348w`} sizes="(max-width: 80px) 100vw, 80px" />
          </Link>
          <Sidebar />
          <div className="hidden md:block w-full md:w-auto">
            <ul className="flex flex-col p-4 mt-4 bg-gray-50 border border-gray-100 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
              {insidePages.map((value, index) => {
                const isThisPage = value.href === pathname
                return (
                  <Link key={index} to={value.href} aria-current={isThisPage && "page"} className={`block py-2 px-3 ${isThisPage ? "text-blue-700" : "text-gray-700"} hover:text-blue-700`}>
                    {value.text}
                  </Link>
                )
              })}
              {socialMedia.map((value, index) => (
                <a key={index} href={value.href} className="block py-2 px-3 text-lg text-gray-400 hover:text-blue-700" target="_blank" rel="noopener noreferrer">
                  {value.icon}
                </a>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
