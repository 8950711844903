import Accordion from "../components/Accordion"
import { GrayBackground, GridSpace, JumboCyan, WhatsAppBanner } from "../components/Sections"
import Typography from "../components/Typography"
import { ubicameURL } from "../utils"

import ourServices1 from "../assets/ourServices1.png"
import ourServices2 from "../assets/ourServices2.png"
import ourServices3 from "../assets/ourServices3.png"
import ourServices4 from "../assets/ourServices4.jpg"
import ourServices5 from "../assets/ourServices5.jpg"
import ourServices6 from "../assets/ourServices6.webp"

function Services() {
  return (
    <>
      <JumboCyan>Nuestros Servicios</JumboCyan>
      <GridSpace
        classCard="p-4 sm:p-5 md:p-6 lg:p-7"
        title={
          <Typography component="h2" className="pb-2">
            Servicios adecuados para cada cliente
          </Typography>
        }
        subtitle={
          <Typography component="p" className="pb-2">
            Ofrecemos diferentes planes para brindarle exactamente lo que necesita
          </Typography>
        }
        objArray={[
          {
            image: ourServices1,
            title: "Seguridad en el hogar",
            description:
              "Un sistema de seguridad para el hogar instalado profesionalmente puede brindarle protección y tranquilidad. Nuestros sistemas detectan y monitorean instrucciones, alarmas de panico o atraco, detección de incendios y sistemas de alerta médica.",
          },
          {
            image: ourServices2,
            title: "Camaras de seguridad",
            description:
              "¡Vea su hogar u oficina desde su teléfono inteligente, tableta o cualquier PC o Mac conectada a Internet en el mundo! Puede acceder a imágenes en vivo o grabadas en cualquier momento. Instalamos cámaras y sistemas DVR profesionales de alta calidad en residencias y edificios comerciales.",
          },
          {
            image: ourServices3,
            title: "Seguridad en el trabajo",
            description:
              "Nuestro monitoreo de alarmas comerciales puede proporcionarle informes que muestran a qué hora se abrió y cerró su negocio cada día, así como qué empleado armó o desarmó su sistema y mucho, mucho más. También podemos enviarle un mensaje de texto o correo electrónico cada vez que se reciba una señal de alarma.",
          },
          {
            image: ourServices4,
            title: "Servicios de localización GPS",
            description:
              "Con nuestros servicios GPS, su vehículo o flota estará segura y monitoreada. Disfrute de rastreo en tiempo real, geocercas para alertas específicas y reportes de conducción. Ideal para gestión de flotas y seguridad vial, nuestros servicios ofrecen control y tranquilidad.",
          },
          {
            image: ourServices5,
            title: "Equipos de control de acceso",
            description:
              "Garantice la seguridad de su propiedad con nuestro sistema de control de acceso. Fácil de gestionar y completamente personalizable, ofrece acceso seguro solo a usuarios autorizados, registro de entradas y salidas, y monitoreo en tiempo real. Ideal para residencias, negocios y áreas restringidas.",
          },
          {
            image: ourServices6,
            title: "Videoporteros Avanzados",
            description:
              "Modernice la entrada de su hogar o empresa con nuestros videoporteros de alta tecnología. Permiten una comunicación visual y auditiva con los visitantes, ofreciendo seguridad mejorada y control de acceso remoto. Instalación profesional para una integración perfecta.",
          },
        ]}
      />
      <WhatsAppBanner />
      <GrayBackground>
        <Typography component="h2">Preguntas frecuentes</Typography>
        <Accordion title="¿Que es un sistema de alarmas?">
          Un sistema de alarma es un elemento de seguridad pasiva. Esto significa que no evitan una situación anormal, pero sí
          son capaces de advertir de ella, cumpliendo así, una función disuasoria frente a posibles problemas.
        </Accordion>
        <Accordion title="¿Quien puede ver las grabaciones de mis camaras de seguridad?">
          Sólo las personas autorizadas. El acceso a las imágenes grabadas debe estar protegido por un usuario y contraseña de
          forma que sólo las personas autorizadas puedan acceder a las mismas.
        </Accordion>
        <Accordion title="¿Por qué se activa sola una alarma?">
          No obstante, si se suele disparar sola la alarma de tu casa, lo normal es que esta incidencia se origine a causa de
          que uno de los sensores esté configurado en una zona de 24 horas. … Ante ello, lo que debes hacer es desactivar la
          alarma apagando la batería de cada sensor detector.
        </Accordion>
        <Accordion title="¿Cómo resetear la alarma de mi casa?">
          Para hacer un reset debes apagar la alarma, y encenderla mientras mantienes presionado el botón de programación. Al
          cabo de 5-10 segundos la alarma volverá a los valores de fábrica.
        </Accordion>
        <Accordion title="¿Cómo puedo acceder a la plataforma de GPS para rastrear mi vehículo?">
          Para acceder a nuestra plataforma de GPS y comenzar a rastrear su vehículo, simplemente haga clic en el siguiente
          enlace:{" "}
          <a href={ubicameURL} target="_blank" rel="noopener noreferrer" className="text-blue-700 underline">
            {ubicameURL}
          </a>
          . Deberá ingresar con sus credenciales de usuario y contraseña proporcionadas durante la instalación o el proceso de
          registro. Una vez dentro, podrá visualizar la ubicación en tiempo real de su vehículo, establecer geocercas, y mucho
          más.
        </Accordion>
      </GrayBackground>
    </>
  )
}

export default Services
