export const facebookURL = "https://www.facebook.com/CentroTecnicoAquila"

export const whatsappURL = "https://api.whatsapp.com/send?phone=528138760066&text=Hola%20me%20puede%20pasar%20informaci%C3%B3n%20sobre%20Centro%20Aquila"

export const whatsappNumber = "+52 813 876 0066"

export const emailContact = "contacto@centroaquila.com"

export const emailSales = "ventas@centroaquila.com"

export const apiBaseURL = "https://api.centroaquila.com/main"

export const reCaptchaSiteKey = "6LdxUVUiAAAAAOIMi3WIMxtiPLlC1xkaJ1tzyBI_"

export const ubicameURL = "https://server.ubicame.lat/login"
