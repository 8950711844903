import { FaEnvelope, FaWhatsapp } from "react-icons/fa"

import { GrayBackground, GridSpace, JumboDark } from "../components/Sections"
import Typography from "../components/Typography"

import { emailContact } from "../utils"

import cableManage from "../assets/cableManage.jpg"
import monitoring from "../assets/monitoring.jpg"

import icon24h7 from "../assets/icon-24-7.png"
import iconPiggy from "../assets/icon-piggy.png"
import iconWrench from "../assets/icon-wrench.png"

import homeServices1 from "../assets/homeServices1.png"
import homeServices2 from "../assets/homeServices2.jpg"
import homeServices3 from "../assets/homeServices3.jpg"

import providerAWS from "../assets/provider-aws.png"
import providerCNORD from "../assets/provider-cnord.png"
import providerCROW from "../assets/provider-crow.png"
import providerHONEY from "../assets/provider-honeywell.png"
import providerSYS from "../assets/provider-syscom.png"

const whyUS = [
  {
    image: iconPiggy,
    title: "Precios Accesibles",
    description: "Proteja lo que mas le importa con los mejores equipos y al mejor precio del mercado",
  },
  {
    image: icon24h7,
    title: "Monitoreo 24/7",
    description: "Contamos con la mas alta tecnología del mercado para ofrecerle el mejor servicio",
  },
  {
    image: iconWrench,
    title: "Expertos Calificados",
    description: "Nuestros técnicos están altamente capacitados en las mejores marcas del mercado",
  },
]

const theProviders = [providerHONEY, providerCROW, providerCNORD, providerAWS, providerSYS]

function Home() {
  return (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-0 h-full">
        <div className="md:order-last h-full">
          <div
            className="h-full bg-left-top bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${cableManage})`,
            }}
          >
            <div className="h-full justify-center items-center flex bg-[#1ac8db] lg:bg-[#1ac8dbf0]">
              <div className="main-padding">
                <div className="text-white">
                  <Typography component="h1" className="pb-1 sm:pb-2 md:pb-3 lg:pb-4">
                    Centro Aquila
                  </Typography>
                  <div className="w-full md:w-3/4">
                    <Typography component="h2" className="pb-5">
                      Seguridad en la que puede confiar
                    </Typography>
                    <Typography component="p">Somos una Unidad de Negocios Especializada en Soluciones de Monitoreo de Alarmas de Intrusión y de Todo Tipo de Sistemas de Video Vigilancia por Cámaras (CCTV) y Controles de Acceso.</Typography>
                  </div>
                  <div className="pt-7 md:pt-12">
                    <button className="font-semibold text-sm md:text-base py-3 md:py-3.5 px-9 md:px-10 rounded-sm" style={{ backgroundColor: "#28e781" }}>
                      <span>Enviar WhatsApp</span>
                      <FaWhatsapp className="inline-block ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="h-full bg-left-top bg-cover bg-no-repeat"
          style={{
            minHeight: "300px",
            backgroundImage: `url(${monitoring})`,
          }}
        ></div>
      </section>
      <JumboDark>
        <FaEnvelope className="text-3xl hidden sm:inline-block mr-4" style={{ color: "#0293B7" }} />
        <span>Escribanos a {emailContact}</span>
      </JumboDark>
      <section className="pt-12 md:pt-16 lg:pt-32 pb-3 md:pb-4 lg:pb-14 px-6 md:px-12 border-b border-solid border-[#ddddddb5]">
        <div className="max-w-6xl mx-auto">
          <div className="flex-initial flex-row lg:flex text-center md:text-left">
            <div className="basis-full md:basis-1/5 lg:basis-1/4 px-2 md:px-6 pb-2">
              <hr className="h-1.5 inline-block w-2/5 sm:w-2/6 md:w-1/4 lg:w-full" style={{ backgroundColor: "#0293B7" }} />
            </div>
            <div className="basis-full px-2 md:px-6">
              <Typography component="h2" className="pb-5">
                ¡Estamos comprometidos a hacer cada trabajo, de la manera correcta!
              </Typography>
              <Typography component="p" className="pb-5">
                Nuestra excelencia y profesionalismo nos distinguen.
              </Typography>
              <Typography component="p" className="pb-5">
                Desde que iniciamos nuestras operaciones, nos hemos comprometido con dar el mejor servicio posible, siendo muy cuidadosos con la eficiencia y la comunicación clara con los clientes.
              </Typography>
              <Typography component="p" className="pb-5">
                La misión de Centro de Monitoreo Aquila es sencilla: proporcionar servicios de alta calidad en tiempo y forma. Nuestro equipo se adapta a cubrir las necesidades específicas para garantizar la excelencia. Esperamos poder cubrir sus necesidades.
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-12 pb-0 md:pb-16 px-6 sm:px-9 md:px-12">
        <div className="max-w-6xl mx-auto text-center">
          <Typography component="h2" className="pb-5">
            ¿Por qué escogernos?
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0">
            {whyUS.map((item, index) => (
              <div key={index} className="inline-block px-4 md:px-5 py-6">
                <div className="pb-4">
                  <img className="mx-auto" width="80" height="80" src={item.image} alt={item.title} loading="lazy" />
                </div>
                <Typography component="h3" className="pb-5">
                  {item.title}
                </Typography>
                <Typography component="p">{item.description}</Typography>
              </div>
            ))}
          </div>
        </div>
      </section>
      <GridSpace
        className="bg-[#1ac8db]"
        classCard="p-4 sm:p-6 md:p-8 lg:p-10 shadow-md hover:shadow-2xl"
        title={
          <Typography component="h2" className="text-white pb-2">
            Nuestros servicios
          </Typography>
        }
        subtitle={
          <Typography component="p" className="text-white pb-6">
            Su tranquilidad es nuestra prioridad
          </Typography>
        }
        objArray={[
          {
            image: homeServices1,
            title: "Seguridad en casa",
            description: "Nuestro sistema de seguridad para el hogar instalado profesionalmente puede brindarle protección y tranquilidad",
          },
          {
            image: homeServices2,
            title: "Camaras de seguridad",
            description: "Con nuestro sistema de camaras de alta calidad instalado profesionalmente puede acceder a secuencias de video en vivo o grabadas desde cualquier lugar y en cualquier momento",
          },
          {
            image: homeServices3,
            title: "Seguridad empresarial",
            description: "Nuestros sistemas de seguridad comercial instalados profesionalmente pueden proporcionarle informes que muestran a qué hora se abrió y cerró su negocio cada día, así como qué empleado armó o desarmó su sistema y mucho, mucho más",
          },
        ]}
      />
      <GrayBackground>
        <Typography component="h5" className="pb-6 sm:pb-8 md:pb-10 lg:pb-12">
          Solo trabajamos con los mejores proveedores para ofrecerle el servicio que usted busca
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2 sm:gap-4 md:gap-6">
          {theProviders.map((provider, index) => (
            <div key={index} className="inline-block">
              <div className="flex items-center h-full p-4 md:p-0">
                <img className="w-full max-w-[50%] md:max-w-full mx-auto" src={provider} alt={`Provider ${index}`} loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </GrayBackground>
    </div>
  )
}

export default Home
