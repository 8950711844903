import { ReactNode } from "react"
import { FaWhatsapp } from "react-icons/fa"

import Typography from "../components/Typography"
import { whatsappNumber, whatsappURL } from "../utils"

export function JumboCyan({ children }: { children: ReactNode }) {
  return (
    <div className="py-16 md:py-20 lg:py-24 px-5 bg-[#27CBDD]">
      <Typography component="h1" className="text-center text-white">
        {children}
      </Typography>
    </div>
  )
}

export function JumboWhite({ children }: { children: ReactNode }) {
  return (
    <div className="py-16 md:py-20 lg:py-24 px-5 bg-white">
      <Typography component="h2" className="text-center text-black">
        {children}
      </Typography>
    </div>
  )
}

export function JumboDark({ children }: { children: ReactNode }) {
  return (
    <section className="py-6 md:py-7 lg:py-8 xl:py-9 px-5 bg-black">
      <Typography component="h3" className="text-center text-white">
        {children}
      </Typography>
    </section>
  )
}

export function WhatsAppBanner() {
  return (
    <JumboDark>
      <div className="max-w-6xl mx-auto flex flex-wrap justify-between flex-col lg:flex-row">
        <div className="inline-flex mx-auto lg:mx-0">Reciba una cotización gratis enviandonos un WhatsApp</div>
        <div className="inline-flex mx-auto lg:mx-0 pt-4 lg:pt-0">
          <a href={whatsappURL}>
            <button className="py-2 px-8 rounded-sm bg-[#28e781]">
              <Typography component="p">
                <FaWhatsapp className="inline-block" />
                <span className="align-middle"> {whatsappNumber}</span>
              </Typography>
            </button>
          </a>
        </div>
      </div>
    </JumboDark>
  )
}

interface IGridSpace {
  className?: string
  classCard?: string
  title?: ReactNode
  subtitle?: ReactNode
  objArray: {
    image: string
    title: string
    description: string
  }[]
}

export function GridSpace({ className, classCard, title, subtitle, objArray }: IGridSpace) {
  return (
    <section className={`pt-12 sm:pt-16 md:pt-20 lg:pt-24 pb-8 sm:pb-14 md:pb-16 lg:pb-24 px-6 sm:px-9 md:px-12 ${className}`}>
      <div className="max-w-6xl mx-auto text-center">
        {title}
        {subtitle}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-3 md:gap-4">
          {objArray.map((item, index) => (
            <div key={index} className={`inline-block my-2 rounded bg-white ${classCard}`}>
              <div className="pb-4">
                <img className="w-full" src={item.image} alt={item.title} loading="lazy" />
              </div>
              <Typography component="h4" className="pb-4">
                {item.title}
              </Typography>
              <Typography component="p">{item.description}</Typography>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export function GrayBackground({ children }: { children: ReactNode }) {
  return (
    <section className="pt-12 sm:pt-14 md:pt-16 pb-6 sm:pb-10 md:pb-14 px-6 sm:px-9 md:px-12 bg-[#f9f9f9]">
      <div className="max-w-6xl mx-auto text-center">{children}</div>
    </section>
  )
}
