import axios from "axios"
import { Field, Form, Formik } from "formik"
import Helmet from "react-helmet"

import { JumboCyan, JumboWhite } from "../components/Sections"
import Typography from "../components/Typography"
import { useGlobalContext } from "../context"
import { reCaptchaSiteKey } from "../utils"

import buildingImage from "../assets/about-us-background.jpg"

function Contact() {
  const { openToast } = useGlobalContext()
  return (
    <>
      <Helmet>
        <script src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`}></script>
      </Helmet>
      <JumboCyan>Contacto</JumboCyan>
      <JumboWhite>Pregunte por nuestras promociones</JumboWhite>
      <section
        className="bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${buildingImage})`,
        }}
      >
        <div className="py-16 md:py-20 lg:py-24 px-4 bg-[#000000E8]">
          <div className="max-w-6xl mx-auto text-center">
            <Typography component="h2" className="pb-2 text-white">
              Reciba una cotización
            </Typography>
            <Typography component="p" className="pb-4 text-white">
              Estamos aquí para ayudar
            </Typography>
            <Formik
              initialValues={{ email: "", phone: "", value: "" }}
              onSubmit={(values, { resetForm }) => {
                grecaptcha.ready(() => {
                  grecaptcha.execute(reCaptchaSiteKey, { action: "submit" }).then((token) => {
                    if (values.email === "skipSend@centroaquila.com") {
                      console.log(token)
                      return resetForm()
                    }
                    axios
                      .post(`https://api.centroaquila.com/main/contact?token=${token}`, values)
                      .then(() => {
                        openToast("Se ha enviado su mensaje", "success")
                      })
                      .catch((error) => {
                        openToast(error?.message ?? "Se ha producido un error", "error")
                      })
                      .finally(() => {
                        resetForm()
                      })
                  })
                })
              }}
            >
              <Form className="text-black">
                <Field required className="custom-input" name="email" placeholder="Email" type="email" />
                <Field required className="custom-input" name="phone" placeholder="Teléfono" />
                <Field required className="custom-input h-32" name="value" placeholder="Mensaje" component="textarea" />
                <button type="submit" className="bg-[#28e781] text-white rounded-sm py-3 px-10 font-semibold capitalize">
                  Enviar
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact

// https://gist.github.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad
