import { SnackbarProvider } from "notistack"
import { Fragment } from "react"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./index.css"

import { WrapperGlobalContext } from "./context"

import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import ScrollToTop from "./components/ScrollToTop"

import Contacto from "./pages/Contacto"
import Home from "./pages/Home"
import { AvisoDePrivacidad, TerminosYCondiciones } from "./pages/LegalStuff"
import Nosotros from "./pages/Nosotros"
import Picadillo from "./pages/Picadillo"
import Playground from "./pages/Playground"
import Productos from "./pages/Productos"
import Servicios from "./pages/Servicios"

const root = createRoot(document.getElementById("root") as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
})

root.render(
  <Fragment>
    <SnackbarProvider maxSnack={5} autoHideDuration={3000} disableWindowBlurListener>
      <QueryClientProvider client={queryClient}>
        <WrapperGlobalContext>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="productos" element={<Productos />} />
              <Route path="servicios" element={<Servicios />} />
              <Route path="nosotros" element={<Nosotros />} />
              <Route path="contacto" element={<Contacto />} />
              {/* Useless */}
              <Route path="playground" element={<Playground />} />
              <Route path="bunny" element={<Picadillo />} />
              {/* Legal */}
              <Route path="aviso-de-privacidad" element={<AvisoDePrivacidad />} />
              <Route path="terminos-y-condiciones" element={<TerminosYCondiciones />} />
              {/* Redirect */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
            <ScrollToTop />
          </BrowserRouter>
        </WrapperGlobalContext>
      </QueryClientProvider>
    </SnackbarProvider>
  </Fragment>
)
