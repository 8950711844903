import { ReactNode, useState } from "react"

import { FaMinus, FaPlus } from "react-icons/fa"
import Typography from "./Typography"

const minusIcon = <FaMinus className="text-gray-500" />
const plusIcon = <FaPlus className="text-blue-700" />

function Accordion({ title, children }: { title: string; children: ReactNode }) {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded((current) => !current)

  return (
    <div className="my-2 sm:my-4 md:my-6 shadow-sm cursor-pointer bg-white" onClick={toggleExpanded}>
      <div className="px-6 text-left items-center h-20 select-none flex justify-between flex-row">
        <Typography component="h5" className="flex-1">
          {title}
        </Typography>
        <div className="flex-none pl-2">{expanded ? minusIcon : plusIcon}</div>
      </div>
      <div className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-linear ${expanded ? "max-h-40" : "max-h-0"}`}>
        <Typography component="p" className="pb-4 text-left">
          {children}
        </Typography>
      </div>
    </div>
  )
}

export default Accordion

// https://freefrontend.com/tailwind-accordions
// https://tailwindcomponents.com/component/accordion-with-tailwindcss
