import axios from "axios"
import { ReactNode } from "react"
import { useQuery } from "react-query"

import { JumboCyan } from "../components/Sections"
import Typography from "../components/Typography"
import { useGlobalContext } from "../context"
import { apiBaseURL } from "../utils"

const CustomTitle = ({ children, hideHR = false }: { children: ReactNode; hideHR?: boolean }) => (
  <>
    {!hideHR && (
      <div className="py-6">
        <hr />
      </div>
    )}
    <Typography component="h2" className="pb-4">
      {children}
    </Typography>
  </>
)

function Playground() {
  const { openToast } = useGlobalContext()

  const { data, isLoading } = useQuery("getSchoolTable", () => axios.get(`${apiBaseURL}/tomorrow`).then((reply) => reply.data))

  const defaultMessage = () => openToast("The default the snackbar")
  const successMessage = () => openToast("This is the success message", "success")
  const failureMessage = () => openToast("This is the error message", "error")

  return (
    <>
      <JumboCyan>Playground</JumboCyan>
      <section className="py-16 md:py-20 lg:py-24 px-4">
        <div className="max-w-6xl mx-auto text-center">
          <CustomTitle hideHR>
            <span data-cy="server-time-title">Tomorrow Server Time</span>
          </CustomTitle>
          <Typography component="h3">
            <span data-cy="server-time-reply">{isLoading ? "loading..." : data?.reply}</span>
          </Typography>
          <CustomTitle>Show Snackbar</CustomTitle>
          <div className="items-center">
            <button data-cy="button-default" className="bg-neutral-600 text-white rounded-sm my-1 mx-2 py-1 px-2" onClick={defaultMessage}>
              Default
            </button>
            <button data-cy="button-success" className="bg-green-600 text-white rounded-sm my-1 mx-2 py-1 px-2" onClick={successMessage}>
              Success
            </button>
            <button data-cy="button-error" className="bg-red-600 text-white rounded-sm my-1 mx-2 py-1 px-2" onClick={failureMessage}>
              Error
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default Playground

// https://gist.github.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad
