import { FaRegDotCircle } from "react-icons/fa"

import { JumboCyan, WhatsAppBanner } from "../components/Sections"
import Typography from "../components/Typography"

import buildingImage from "../assets/about-us-background.jpg"
import elonMusk from "../assets/about-us-elon-musk.jpg"
import icon24h7 from "../assets/icon-24-7.png"
import iconWrench from "../assets/icon-wrench.png"

const circlesArray = ["Responsabilidad", "Transparencia", "Compromiso", "Integridad", "Honestidad", "Calidad"]

const sinceYear = 2020

const numbersArray = [
  {
    icon: iconWrench,
    time: new Date().getFullYear() - sinceYear,
    text: "Años en el mercado",
  },
  {
    icon: icon24h7,
    time: "24 / 7",
    text: "Sistemas funcionando",
  },
]

function AboutUS() {
  return (
    <>
      <JumboCyan>Sobre Nosotros</JumboCyan>
      <section
        className="bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${buildingImage})`,
        }}
      >
        <div className="py-12 sm:py-14 md:py-16 lg:py-20 px-8 sm:px-10 md:px-12 lg:px-14 bg-[#f9f9f9] opacity-[.98]">
          <div className="max-w-6xl mx-auto">
            <div className="flex-initial lg:flex flex-row md:flex-row-reverse">
              <div className="basis-full px-2 sm:px-4 md:px-6 text-center lg:text-left">
                <Typography component="h2" className="pb-4 lg:pb-6">
                  Profesionalismo, excelencia y puntualidad
                </Typography>
                <Typography component="p" className="pb-4 lg:pb-6">
                  Desde el {sinceYear}, Centro de Monitoreo Aquila ha ofrecido servicios de calidad con la atención profesional
                  que nuestros clientes merecen. Contáctenos para saber más de Servicio de sistemas de seguridad.
                </Typography>
                <Typography component="p" className="pb-4 lg:pb-6">
                  Desde que iniciamos nuestras operaciones, nos hemos comprometido con dar el mejor servicio posible, siendo muy
                  cuidadosos con la eficiencia y la comunicación clara con los clientes.
                </Typography>
                <Typography component="p" className="pb-4 lg:pb-6">
                  La misión de Centro de Monitoreo Aquila es sencilla: proporcionar servicios de alta calidad en tiempo y forma.
                  Nuestro equipo se adapta a cubrir las necesidades específicas para garantizar la excelencia. Esperamos poder
                  cubrir sus necesidades.
                </Typography>
              </div>
              <div className="basis-full sm:basis-1/2 md:basis-2/3 px-2 sm:px-4 md:px-6 pt-4 lg:pt-0">
                <img src={elonMusk} alt="Elon Musk Russia" className="mx-auto w-3/4 sm:w-1/2 md:w-1/2 lg:w-full" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="py-12 sm:py-14 md:py-16 lg:py-20 px-8 sm:px-10 md:px-12 lg:px-14">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-3 md:gap-4">
            {circlesArray.map((circle) => (
              <div className="inline-block py-1 md:py-2">
                <div className="flex items-center justify-center sm:justify-start">
                  <Typography component="h3" className="px-4 inline-block">
                    <FaRegDotCircle className="text-[#2171ff] inline-block" />
                  </Typography>
                  <Typography component="h4" className="inline-block">
                    {circle}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <WhatsAppBanner />
      <section className="py-12 sm:py-14 md:py-16 lg:py-20 px-8 sm:px-10 md:px-12 lg:px-14">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 md:gap-4">
            {numbersArray.map((item) => (
              <div className="inline-block py-2 text-center items-center">
                <img className="mx-auto pb-3" src={item.icon} alt="Time icon" />
                <Typography component="h1" className="pb-3 font-semibold">
                  {item.time}
                </Typography>
                <Typography component="h6" className="pb-2 font-medium">
                  {item.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUS
